import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const siteName = 'Frame.io Developer';

function buildFaq(faqEntity) {
  return JSON.stringify({
    "@type": "Question",
    "name": faqEntity.question,
    "acceptedAnswer":{
      "@type": "Answer",
      "text": documentToHtmlString(JSON.parse(faqEntity.answer.raw))
    }
  })
}

function SEO(props) {
  const { title, description, faqs } = props;

  // console.log(faqs.map(faq => buildFaq(faq)))

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="google-site-verification" content="wWHcjR-0D1Ddo2Dlxa5OEYRJfcrL67ILpY9Xp_TWTQE" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:description" content={description} />
      <script type="application/ld+json" id="Logo">
        {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Frame.io",
          "url": "http://www.frame.io",
          "logo": "https://dka575ofm4ao0.cloudfront.net/pages-transactional_logos/retina/22145/azA7aad0SPiJCTOHAtuc"
        }`}
      </script>
      {faqs &&
        <script type="application/ld+json" id="FAQs">
          {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [${faqs.map(faq => buildFaq(faq))}]
            }`}
        </script>
      }
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  faqs: PropTypes.any
};

export default SEO;
